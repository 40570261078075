import { problemAwareHttp, http } from '@/http';
import CommonService from '@/pages/commonService';

export default class DeploymentService {
  /**
   * @param {object} options
   * @property {number} page
   * @property {number} size
   * @property {number} sortField
   * @property {number} sortOrder
   * @property {number} name
   * @return {Promise<Array<Object>>}
   */
  static async getAll({ page, size, sortField, sortOrder, name, organization_id, dedicated}) {
    const params = {
      sort: sortField + ',' + sortOrder,
      page,
      size,
    };

    if (name) {
      params.name = name;
    }

    if (dedicated) {
      params.dedicated = dedicated;
    }

    const headers = {};

    if (organization_id) {
      headers['X-Ciam-Org-Id'] = organization_id;
    }

    const response = await problemAwareHttp.get('/deployments', {
      params,
      headers,
    });

    if (!response || !response.data || !response.data._embedded) {
      const fetchPromise = new Promise((resolve, reject) => {
        reject(new Error('Error while fetching the deployment list'));
      });
      
      return fetchPromise.catch(error => {
        if(response && response.data && response.data.page && response.data.page.totalElements == 0) {
          console.warn('WARN: No deployment found with this name');
        } else {
          if(response.config.params.page > response.data.page.totalPages) {
            console.error("The page you are trying to reach is not available", error)
          } else {
            console.error(error)
          }
        }
        
        return Promise.resolve([]);
      });
    }

    const { data } = response;

    return data;
  }

  /**
   * Returns the instances of deployment
   * @param deploymentId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getInstances(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/instances/`).then((response) => response.data);
  }

  /**
   * Returns the instance detail of deployment
   * @param instanceId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getInstance(instanceId) {
    return problemAwareHttp.get(`/instances/${instanceId}/`).then((response) => response.data);
  }

  /**
   * Patch the instance status of deployment
   * @param instanceId
   * @param status
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async setInstanceStatus(instanceId, status) {
    return problemAwareHttp.patch(`/instances/${instanceId}/status`, { status: status }).then((response) => response.data);
  }

  /**
   * Delete the instance of deployment
   * @param instanceId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteInstance(instanceId) {
    return problemAwareHttp.delete(`/instances/${instanceId}/`).then((response) => response.data);
  }

  /**
   * Admin only, trigger a rolling-update redeployment of instance
   * @param {string} instanceId uuid
   * @param {string} mode mode of redeploy
   * @return {Promise<number>}
   */
  static async redeployInstance(instanceId, mode) {
    const response = await http.post(`/instances/${instanceId}/tasks/deploy?mode=${mode}`).then((response) => {
      if (!response) {
        return Promise.reject();
      }

      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }

      return response;
    });

    return response.status;
  }

  /**
   * Promote the instance to be live
   * @param instanceId
   * @returns {Promise<AxiosResponse<any>>}
   * */
  static async promoteInstanceLive(instanceId) {
    return problemAwareHttp.post(`/instances/${instanceId}/live`).then((response) => response.data);
  }

  /**
   * Returns the instance topology
   * @param deploymentId
   * @returns {Promise<AxiosResponse<Topology>>}
   */
  static async getTopology(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/instance/topology`).then((response) => response.data);
  }

  /**
   * Trigger the topology reload
   * @param deploymentId
   * @returns {Promise<AxiosResponse<Topology>>}
   */
  static async reloadTopology(deploymentId) {
    return problemAwareHttp.get(`/jobs/reload-topology/${deploymentId}`).then((response) => {
      response.data;
    });
  }

  /**
   * Returns the metrics of a deployment
   * @param deploymentId
   * @param questionId
   * @param yearFilter
   * @param monthFilter
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getMetrics(deploymentId, questionId, yearFilter, monthFilter) {
    return problemAwareHttp.post(`/deployments/${deploymentId}/dashboards/${questionId}`, {
      'year_filter': yearFilter,
      'month_filter': monthFilter,
    }).then((response) => response.data);
  }

  /**
   * @typedef Statistics
   * @type {object}
   */

  /**
   * @typedef DeploymentWithStatistics
   * @type {object}
   * @property {Statistics} statistics
   */

  /**
   * @typedef DeploymentAudit
   * @type {object}
   * @property {String} id
   * @property {String} deploymentId
   * @property {String} emitter
   * @property {String} action
   * @property {String} resource
   * @property {String} message
   * @property {object} details
   * @property {String} occurredAt
   */

  static async getStatistics(deployment) {
    if (!deployment?._links?.stats?.href) {
      return Promise.reject();
    }
    const response = await problemAwareHttp.get(deployment._links.stats.href);

    if (!response) {
      return Promise.reject();
    }

    return response.data;
  }

  static async getUsage(deployment) {
    if (!deployment?._links?.metrics?.href) {
      return Promise.reject();
    }

    const response = await problemAwareHttp.get(deployment._links.metrics.href.replace('metrics', 'usage'));

    if (!response) {
      return Promise.reject();
    }

    return response.data;
  }

  static async get(id) {
    return await CommonService.get('deployments', id);
  }

  static async remove(deployment) {
    const response = await http.delete(deployment._links.self.href);

    if (!response) {
      return Promise.reject();
    }

    return response.status;
  }

  /**
   *
   * @param {string}  deploymentId the deployment id
   * @returns {Promise<object>} the object holding the crednetials
   */
  static async getCredentials(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/credentials`).then((response) => response.data);
  }

  /**
   * Admin only, trigger a rolling-update redeployment
   * @param {string} deployment_id uuid
   * @param {string} mode mode of redeploy
   * @return {Promise<number>}
   */
  static async redeploy(deployment_id, mode) {
    const response = await http.post(`/deployments/${deployment_id}/tasks/deploy?mode=${mode}`).then((response) => {
      if (!response) {
        return Promise.reject();
      }

      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }

      return response;
    });

    return response.status;
  }

  /**
   * Admin only, trigger a metric configuration
   * @param {string} deployment_id uuid
   * @return {Promise<number>}
   */
  static async metricConfiguration(deployment_id) {
    const response = await http.post(`/deployments/${deployment_id}/tasks/metrics-configuration`);

    if (!response) {
      return Promise.reject();
    }

    return response.status;
  }

  /**
   * Admin only, finalize the deployment (service account, active monitoring, ...)
   * @param {string} deployment_id uuid
   * @return {Promise<number>}
   */
  static async finalizeConfiguration(deployment_id) {
    const response = await http.post(`admin/notifications/deployments/${deployment_id}/finalize`);

    if (!response) {
      return Promise.reject();
    }

    return response.status;
  }

  /**
   *
   * @param deploymentId {String}
   * @returns {Promise<AxiosResponse<AllowlistConfiguration>>}
   */
  static async getAllowlistConfiguration(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/allowlist`).then((result) => result.data);
  }


  static async isUpgradeAvailable(organizationId) {
    return problemAwareHttp.get(`/deployments/${organizationId}/upgrades`).then(response => response.data);
  }

  static async disasterRecovery(deploymentId, parameters) {
    return problemAwareHttp.post(`/deployments/${deploymentId}/duplicate`, parameters).then(response => response.data);
  }

  static async createBackup(deploymentId, parameters) {
    await problemAwareHttp.post(`/deployments/${deploymentId}/backups/`, parameters).then(response => {
      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }
      return response.data;
    });
  }

  static async getMaintenanceWindow(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/maintenances/settings`).then(response => {
      const maintenanceWindow = this.getTimeFromOffsetString(response.data.maintenanceWindowStart);
      return {
        ...maintenanceWindow,
        duration: response.data.maintenanceWindowDuration.replace('PT', '').replace('H', ''),
      };
    });
  }

  static async setMaintenanceWindow(deploymentId, maintenance) {
    return problemAwareHttp.put(`/deployments/${deploymentId}/maintenances/settings`, {
      deploymentId,
      maintenanceWindowStart: `${maintenance.hours}:${maintenance.minutes}${maintenance.utc}:00`,
      maintenanceWindowDuration: `PT${maintenance.duration}H`,
    });
  }


  static getTimeFromOffsetString(offsetString) {
    // Extraire l'heure et la minute de la chaîne
    const [time, offset] = offsetString.split('+');
    var [hours, minutes] = time.split(':');
    minutes = minutes.substring(0, 2);
    return {
      utc: offsetString.substring(5, 8),
      hours,
      minutes,
    };
  }


  /**
   *
   * @param deploymentId {String}
   * @param status {String}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static setStatus(deploymentId, status) {
    return problemAwareHttp.put(`/deployments/${deploymentId}/status`, { status });
  }

  /**
   *
   * @param deploymentId {String}
   * @param configuration {AllowlistConfiguration}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static setAllowlistConfiguration(deploymentId, configuration) {
    return problemAwareHttp.put(`/deployments/${deploymentId}/allowlist`, configuration).then((response) => {
      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }
      return response;
    });
  }

  /**
   * @param {String} deploymentId
   * @param {string} url
   * @return {Promise<DeploymentAudit[]>}
   */
  static async getAudits(deploymentId, url = `/deployments/${deploymentId}/audits`) {
    return problemAwareHttp
      .get(url)
      .then((response) => [response.data._links?.next?.[0]?.href, response.data?._embedded?.deploymentAudits || []]);
  }
}
